var isMobile = mobileAndTabletcheck();
var scrollTop = 0,
	menu = $("#menu"),
    header = $("header"),
	wrapper = $(".wrapper");

function deferImageLoad() {
    $("img[data-src]").each(function(){
        if($(this).data('src') != ''){
            $(this)
            .css('opacity', 0)
            .attr('src', $(this).data('src'))
            .on('load', function(){
                $(this).fadeTo(800, 1).attr('data-src', '');
            });
        }
    })
    $("[data-background-src]").each(function(){
        if($(this).data('src') != ''){
            $(this).css('background-image', 'url(' + $(this).data('background-src') + ')');
        }
    })
}

function openMainNewsBox(){
    $("#main-news-box a").click(function(e){
        $.cookie('mainNewsSeen', 1, { expires: 7 });
    })
    $.fancybox.open({
        src  : '#main-news-box',
        type : 'inline',
        opts : {
            // smallBtn : false,
            // clickSlide: '',
            // clickOutside: '',
            touch: false,
            // btnTpl: {
            //     // This small close button will be appended to your html/inline/ajax content by default,
            //     // if "smallBtn" option is not set to false
            //     smallBtn:
            //       '<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small" title="{{CLOSE}}">' +
            //       "</button>"
            // },
            afterShow : function( instance, current ) {
                // console.info( 'done!' );
            },
            afterClose: function( instance, current ) {
                // location.reload();
                $.cookie('mainNewsSeen', 1, { expires: 7 });
            }
        }
    });
}

$(function(){
    // $(".wrapper").css('opacity', 0);

    if(isMobile){
        $("html").addClass("is-mobile");
    }else{
        $("html").addClass("is-desktop");
    }

    $.extend($.scrollTo.defaults, {
        axis: 'y',
        duration: 1500,
        easing: 'easeOutCubic',
        onAfter: function(){
            if(typeof syncActiveLine !== "undefined"){
                syncActiveLine();
            }
        }
    });

    $("[data-scroll-to]").click(function(e){
        var elem = $(this).data("scroll-to");
        if($("#" + elem).length) $.scrollTo($("#" + elem));
    })

    $(".dialog-button", menu).not('.animated').click(function(e){
        if(menu.hasClass('expanded')){
            menu.addClass('animated');
            menu.transition({
                x: 0
            }, 900, 'easeOutCubic', function(){
                menu.removeClass('animated').removeClass('expanded');
            });
        }else{
            menu.addClass('animated');
            menu.transition({
                x: "100%"
            }, 900, 'easeOutCubic', function(){
                menu.removeClass('animated').addClass('expanded');
            });
            $(".logo", menu)
            .css({
                'opacity': 0,
            }).transition({
                opacity: '1',
                delay: 600
            }, 1000);
            $(".page-list li", menu).each(function(i){
                $(this).css({
                    'opacity': 0,
                    'transform': 'translate(0, ' + (20) + 'px)',
                }).transition({
                    y: '0',
                    opacity: '1',
                    delay: 700 + (100 * i)
                }, 1000, 'easeOutExpo');
            })
            $(".contact-block", menu).css({
                'opacity': 0,
            }).transition({
                opacity: '1',
                delay: 2000
            }, 1000);
        }
    })

    var menuMoving = false;
    $("button.hamburger").click(function(e){
        e.preventDefault();
        if(menuMoving) return false;
        if(menu.hasClass("open")){
            menuMoving = true;
            menu.transition({
                y: "-100%",
                opacity: 0,
            }, 1000, 'easeInOutCubic', function(){
                menuMoving = false;
            });
            $(this).removeClass('is-active');
            menu.removeClass("open");
            // $("html").removeClass('noscrolling');
        }else{
            menuMoving = true;
            $(this).addClass('is-active');
            menu
            .addClass('open')
            .css({
                'transform':'translate(0, -100%)',
                'opacity': 1,
            })
            .transition({
                y: '0',
            }, 1000, 'easeInOutCubic', function(){
                menuMoving = false;
            });

            $(".lang", menu)
            .css({
                opacity:0
            })
            .transition({
                opacity:1,
                delay:1000,
            }, 600)

            $(".page-list li", menu).each(function(i){
                $(this).css({
                    'opacity': 0,
                    'transform': 'translate(0, ' + (20) + 'px)',
                }).transition({
                    y: '0',
                    opacity: '1',
                    delay: 700 + (100 * i)
                }, 1000, 'easeOutExpo');
            })

            $(".contact-block", menu).css({
                'opacity': 0,
            }).transition({
                opacity: '1',
                delay: 2000
            }, 1000);

            // $("html").addClass('noscrolling');
        }
    })

	$(document).on('click touchend', function(e){
        if(!$(e.target).closest('#menu').length && menu.hasClass('expanded')){
            // close menu
            $(".dialog-button", menu).trigger("click");
        }
	})
    
	$(window).resize(function(){
	}).trigger('resize');

	$(window).scroll(function(){
		scrollTop = $(window).scrollTop();
        if(scrollTop < 30){
            header.removeClass('mini-size');
        }else{
            header.addClass('mini-size');
        }
	});
})

$(window).load(function() {
	$(".preload").removeClass("preload");
	$(".anim").addClass("ready");
	$(window).trigger('scroll');
    // $(".wrapper").fadeTo(800, 1);
    $("#loader").fadeOut(800);
    deferImageLoad();
    AOS.init({
        // Global settings:
        disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
        // startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
        initClassName: 'aos-init', // class applied after initialization
        animatedClassName: 'aos-animate', // class applied on animation
        useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
        disableMutationObserver: false, // disables automatic mutations' detections (advanced)
        debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
        throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


        // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
        offset: 120, // offset (in px) from the original trigger point
        delay: 0, // values from 0 to 3000, with step 50ms
        duration: 800, // values from 0 to 3000, with step 50ms
        easing: 'ease', // default easing for AOS animations
        once: true, // whether animation should happen only once - while scrolling down
        mirror: false, // whether elements should animate out while scrolling past them
        anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });
    
    if(!$.cookie('mainNewsSeen') && $("#main-news-box").length) openMainNewsBox();
})